import { useEffect, Suspense, lazy } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { I18nProvider } from "@lingui/react";
import { i18n } from "@lingui/core";
import { defaultLocale, dynamicActivate, appLanguages } from "./i18n";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AppLayout from "layouts/AppLayout";
import initAppInsights from "utils/AppInsights";
import { Toaster } from "sonner";

import "styles/global.scss";
import Cookies from "js-cookie";

const LoginPage = lazy(() => import("pages/Login"));
const PraicingPage = lazy(() => import("pages/Praicing"));
const WelcomePraicingPage = lazy(() => import("pages/WelcomePraicing"));
const JoinTeamPage = lazy(() => import("pages/JoinTeam"));
const ApplyForJobPage = lazy(() => import("pages/ApplyForJob"));
const NotFound = lazy(() => import("pages/NotFound"));
const Unauthorized = lazy(() => import("pages/Unauthorized"));

function App(): JSX.Element {
  initAppInsights();

  const queryClient = new QueryClient({
    defaultOptions: { queries: { retry: false } },
  });

  useEffect(() => {
    // TODO: amend the logic below once whole app/languages are migrated.
    // Persisting language in cookies / syncing with DB shoule be moved to this app as well.
    const selectedLanguage = Cookies.get("language");
    if (selectedLanguage) {
      const { name } = JSON.parse(selectedLanguage);
      const selectedLocale = name === "en_US" ? "en_GB" : name;
      const language = appLanguages.find(
        (lang) => lang.locale === selectedLocale
      );
      dynamicActivate(language?.locale || defaultLocale);
    } else {
      dynamicActivate(defaultLocale);
    }
  }, []);

  return (
    <I18nProvider i18n={i18n}>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <Suspense fallback={<AppLayout />}>
            <Routes>
              <Route path="/login" element={<LoginPage />} />
              <Route path="/welcome" element={<WelcomePraicingPage />} />
              <Route path="/job/:jobId" element={<ApplyForJobPage />} />
              <Route path="/team/:teamId" element={<JoinTeamPage />} />
              <Route path="/welcome" element={<WelcomePraicingPage />} />
              <Route path="/:shortId" element={<PraicingPage />} />
              <Route path="/404" element={<NotFound />} />
              <Route path="/401" element={<Unauthorized />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Suspense>
        </QueryClientProvider>
      </BrowserRouter>
      <Toaster position="top-right" closeButton={true} />
    </I18nProvider>
  );
}

export default App;
